import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheNavbar = _resolveComponent("TheNavbar")
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")
  const _component_BottomButtons = _resolveComponent("BottomButtons")
  const _component_ion_tabs = _resolveComponent("ion-tabs")
  const _component_ion_page = _resolveComponent("ion-page")

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_TheNavbar),
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_BottomButtons)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}