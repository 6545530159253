import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")
  const _component_ion_buttons = _resolveComponent("ion-buttons")
  const _component_ion_title = _resolveComponent("ion-title")
  const _component_TheUserAuthButton = _resolveComponent("TheUserAuthButton")
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")
  const _component_ion_header = _resolveComponent("ion-header")

  return (_openBlock(), _createBlock(_component_ion_header, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, { slot: "start" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('weekly_goals')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_buttons, { slot: "end" }, {
            default: _withCtx(() => [
              _createVNode(_component_TheUserAuthButton)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}